import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { lazyload } from '@/utils/index'

NProgress.configure({
	showSpinner: false
}) // NProgress Configuration

router.beforeEach(async (to, from, next) => {
	// start progress bar
	NProgress.start()
	setTimeout(function(){
		const imgs=document.querySelectorAll('.lazy');
		lazyload(imgs)
		window.onscroll =function(){
			lazyload(imgs);
		}
	},100)
	next({ path: to })
})

router.afterEach(() => {
	// finish progress bar
	NProgress.done()
})
