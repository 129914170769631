import Vue from 'vue'
import App from './App'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import 'normalize.css/normalize.css'
import "./icons/iconfont.css" 
import vueSmoothScroll from 'vue2-smooth-scroll'
import './permission' // permission control

import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

if (process.env.NODE_ENV === 'development') {
	console.log('dev')
}

Vue.use(Element)
Vue.use(vueSmoothScroll)
Vue.config.productionTip = false
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  render: h => h(App)
})
