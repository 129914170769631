import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/Layout'

export const constantRoutes = [
	{
		path: '/redirect',
		component: Layout,
		hidden: true,
		children: [{
			path: '/redirect/:path(.*)',
			component: () => import('@/views/redirect/index')
		}]
	},
	{
		path: '/',
		component: Layout,
		redirect: '/index',
		children: [{
			path: 'index',
			component: () => import('@/Layout/appMain'),
			name: 'index',
			meta:{title: '杭州远舟医疗科技有限公司'}
		}]
	},
	{
		path: '/about',
		component: Layout,
		redirect: '/about/index',
		children: [{
			path: 'index',
			component: () => import('@/views/about/index'),
			name: 'about',
			meta:{title: '关于我们'}
		}]
	},
	{
		path: '/product',
		component: Layout,
		redirect: '/product/index',
		children: [{
			path: 'index',
			component: () => import('@/views/product/index'),
			name: 'product',
			meta:{title: '产品及解决方案'}
		}]
	},
	{
		path: '/customer',
		component: Layout,
		redirect: '/customer/index',
		children: [{
			path: 'index',
			component: () => import('@/views/customer/index'),
			name: 'customer',
			meta:{title: '客户和伙伴'}
		}]
	},
]
const createRouter = () => new Router({
	// mode: 'history', // require service support
	mode: 'hash', 
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}
export default router
