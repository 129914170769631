<template>
	<div>
		<go-top></go-top>
		
		<top-bar @handlerheader="gotoAbout" @togglePage="handlerPage"></top-bar>
		
		<div style="min-height: calc(100vh - 252px);">
			<router-view></router-view>
		</div>	
		
		<foot></foot>
		
		</div>
</template>

<script>
	
	
	import TopBar from './header/index'
	import GoTop from '@/components/gotop/index'
	import Foot from '@/components/foot/index'
	
	export default {
		name: 'Layout',
		components:{TopBar,GoTop,Foot},
		methods:{
			gotoAbout(val){
				if(this.$route.path!=='/index'){
					this.$router.push('/index')
					setTimeout(()=>{
						document.querySelector(val).scrollIntoView({ behavior: 'smooth' ,block: 'start'});
						this.addcolor()
						this.setTitle()
					},100)
				}else{
					document.querySelector(val).scrollIntoView({ behavior: 'smooth' ,block: 'start'}); 
				}
			},
			handlerPage(val){
				if(!this.$route.path.startsWith(val)){
					this.$router.push(val);
					setTimeout(()=>{
						this.addcolor()
						this.setTitle()
					},100)
				}
			},
			clearclass(){
				const lis=document.querySelectorAll('.header-text>ul>li')
				lis.forEach((li)=>{
					let cls=li.classList
					cls.remove('activecolor')
				})
				let towlis=document.querySelectorAll('.two_ul>li')
				towlis.forEach((li)=>{
					li.classList.remove('activecolor')
				})
				
				const li2s=document.querySelectorAll('wapper-text>ul>li')
				li2s.forEach((li)=>{
					li.classList.remove('activecolor')
				})
			},
			addcolor(){
				const fullpath=this.$route.path
				let i=fullpath.lastIndexOf('/')
				if(i===0){
					i=fullpath.length
				}
				const path=fullpath.substring(1,i)
				this.clearclass()
				switch(path){
					case 'index':
						document.querySelector('.header-text>ul>li:nth-of-type(1)').classList.add('activecolor')
						let towlis=document.querySelectorAll('.two_ul>li')
						towlis.forEach((li)=>{
							li.classList.add('activecolor')
						})
						break;
					case 'about':
						document.querySelector('.header-text>ul>li:nth-of-type(2)').classList.add('activecolor')
						break;
					case 'product':
						document.querySelector('.header-text>ul>li:nth-of-type(3)').classList.add('activecolor')
						break;
					case 'customer':
						document.querySelector('.header-text>ul>li:nth-of-type(4)').classList.add('activecolor')
						break;
				}
			},
			setTitle(){
				document.title = this.$route.meta.title
			}
		},
		mounted() {
			this.addcolor()
			this.setTitle()
		}
	}
</script>

<style>
</style>
