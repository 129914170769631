<template>
	<div class="foot">
		<span>Copyright © 杭州远舟医疗科技有限公司 </span>
		<a href="https://beian.miit.gov.cn" target="_blank">
			ICP备案号：浙ICP备17011435号-4
		</a>
		<span>浙公网备案:33010802009686号</span>
		<span>
			官网：
			<a href="http://www.chnyz.net" target="_blank">
				http://www.chnyz.net
			</a>
		</span>
		<span>
			互联网药品信息服务资格证书
			<a :href="require('@/assets/picture/yaopin_new.png')" target="_blank">
				（浙）-非经营性-2018-0075经营证照
			</a>
		</span>
		<span>
			增值电信业务经营许可证
			<a :href="require('@/assets/picture/jinyin_new.png')" target="_blank">
				浙B2-20190969
			</a>
		</span>
		
	</div>
</template>

<script>
	export default {
		name: 'Foot'
	}
</script>

<style lang="scss">
	@import './index.scss'
</style>
