<template>
	<transition name="gototop">
		<div class="gotop" @click="gotoAbout('#app')" v-if="scrollFlag">
			<span class="iconfont icon-shangjiantou"></span>
		</div>
	</transition>
</template>

<script>
	export default {
		name: 'GoTop',
		data(){
			return{
				scrollFlag: false
			}
		},
		mounted() {
			this.handleScroll()
			window.addEventListener('scroll', this.handleScroll, true)
		},
		methods:{
			//滑动超过200时显示按钮
			handleScroll() {
			    let scrollTop = document.documentElement.scrollTop
			    if (scrollTop > 100) {
			        this.scrollFlag = true
			    } else {
			        this.scrollFlag = false
			    }
			},
			gotoAbout(val){
				document.querySelector(val).scrollIntoView({ behavior: 'smooth' ,block: 'start'});
			}
		},
		destroyed() {
		    window.removeEventListener('scroll',this.handleScroll,true) //移除监听事件
		},
	}
</script>

<style lang="scss">
	@import './index.scss';
</style>
