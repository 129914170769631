<template>
	<div class="header-contain">
		<div class="logo-div">
			<a href="/" style="display: inline-block;">
				<img width="187" height="111" src="http://www.chnyz.net/wp-content/uploads/2021/04/logo.jpg"
					class="logo" alt="" loading="lazy">
			</a>
		</div>
		<div class="header-text">
			<ul>
				<li class="index-li">
					<a @click="togglePage('/index')">
						首页
						<i class="el-icon-arrow-down el-icon--right"></i>
					</a>
					<ul class="two_ul">
						<li @click="gotoAbout('.main-2-wapper1')">关于远舟</li>
						<li @click="gotoAbout('.main-3-wapper1')">核心优势</li>
						<li @click="gotoAbout('.main-4-wapper1')">主要产品</li>
						<li @click="gotoAbout('.main-5-wapper1')">核心团队</li>
						<li @click="gotoAbout('.main-6-wapper1')">蓝谷智库</li>
						<!-- <li @click="gotoAbout('.main-7-wapper1')">服务支持</li> -->
						<li @click="gotoAbout('.main-8-wapper1')">主要合作伙伴与客户</li>
						<!-- <li @click="gotoAbout('.main-9-wapper1')">联系我们</li> -->
					</ul>
				</li>
				<li @click="togglePage('/about')">
					关于我们
				</li>
				<li @click="togglePage('/product')">
					产品及解决方案
				</li>
				<li @click="togglePage('/customer')">
					客户和伙伴
				</li>
			</ul>
		</div>
		<div class="hanbaobao" @click="openwapper">
			<span class="iconfont icon-hanbaobao"></span>
		</div>
		<!-- 移动端遮罩 -->
		<div class="header-wapper" v-if="show" @click="closewapper">
		</div>
		<transition name="wapper-donghua">	
			<div class="wapper-text" v-if="show">
				<div class="close" @click="closewapper">
					<span class="iconfont icon-cha"></span>
				</div>
				<ul>
					<li class="index-li1" :class="{'grcolor':ultwoShow}">
						<a @click="togglePage('/index')">
							首页
						</a>
						<i class="el-icon-arrow-down el-icon--right" @click="toggleli" :class="{'fani':ultwoShow}"></i>
						<ul class="two_ul" v-show="ultwoShow">
							<li @click="gotoAbout('.main-2-wapper1')">关于远舟</li>
							<li @click="gotoAbout('.main-3-wapper1')">核心优势</li>
							<li @click="gotoAbout('.main-4-wapper1')">主要产品</li>
							<li @click="gotoAbout('.main-5-wapper1')">核心团队</li>
							<li @click="gotoAbout('.main-6-wapper1')">蓝谷智库</li>
							<!-- <li @click="gotoAbout('.main-7-wapper1')">服务支持</li> -->
							<li @click="gotoAbout('.main-8-wapper1')">主要合作伙伴与客户</li>
							<!-- <li @click="gotoAbout('.main-9-wapper1')">联系我们</li> -->
						</ul>
					</li>
					<li @click="togglePage('/about')">
						关于我们
					</li>
					<li @click="togglePage('/product')">
						产品及解决方案
					</li>
					<li @click="togglePage('/customer')">
						客户和伙伴
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
	export default {
		name: 'Header',
		data(){
			return{
				ultwoShow: false,
				show: false
			}
		},
		methods:{
			gotoAbout(val){
				this.show=false
				this.$emit('handlerheader',val)
			},
			togglePage(val){
				this.show=false
				this.$emit('togglePage',val)
			},
			openwapper(){
				this.show=true
				setTimeout(()=>{
					this.addcolor()
				},100)
			},
			closewapper(){
				this.show=false
			},
			toggleli(){
				this.ultwoShow=!this.ultwoShow
				if(!this.ultwoShow){
					setTimeout(()=>{
						this.addcolor()
					},100)
				}
			},
			clearclass(){
				let towlis=document.querySelectorAll('.two_ul>li')
				towlis.forEach((li)=>{
					li.classList.remove('activecolor')
				})
				
				const li2s=document.querySelectorAll('.wapper-text>ul>li')

				li2s.forEach((li)=>{
					li.classList.remove('activecolor')
				})
			},
			addcolor(){
				const fullpath=this.$route.path
				let i=fullpath.lastIndexOf('/')
				if(i===0){
					i=fullpath.length
				}
				const path=fullpath.substring(1,i)
				this.clearclass()
				switch(path){
					case 'index':
						let towlis=document.querySelectorAll('.two_ul>li')
						towlis.forEach((li)=>{
							li.classList.add('activecolor')
						})
						
						document.querySelector('.wapper-text>ul>li:nth-of-type(1)').classList.add('activecolor')
						break;
					case 'about':
						document.querySelector('.wapper-text>ul>li:nth-of-type(2)').classList.add('activecolor')
						break;
					case 'product':
						document.querySelector('.wapper-text>ul>li:nth-of-type(3)').classList.add('activecolor')
						break;
					case 'customer':
						document.querySelector('.wapper-text>ul>li:nth-of-type(4)').classList.add('activecolor')
						break;
				}
			}
		},
		watch:{
			show: function(newval,oldval){
				if(newval){
					document.body.parentNode.style.overflow = "hidden";
				}else{
					document.body.parentNode.style.overflow = "auto";
				}
			}
		},
	}
</script>

<style lang="scss">
	@import "./index.scss";
</style>
