<template>
	<div id="app">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'App'
	}
</script>

<style>
	html,
	body,
	#app {
		min-height: 100%;
		font-family: "Poppins,arial, helvetica, sans-serif";
		overflow-x: hidden;
	}
</style>
